<template>
    <DxLookup v-if="!view" :data-source="dataSource" :display-expr="displayExpr" value-expr="_id" v-model:value="editableValue" :showClearButton="showClearButton" :showCancelButton="false" @value-changed="manualChange" >
        <DxDropDownOptions :show-title="false" :hide-on-outside-click="true" :min-width="minWidth" />
        <template #item="itemData">
            <div  :title="getTooltipText(itemData.data._id)">
               {{ itemData.data.Name }}
            </div>
        </template>
    </DxLookup>
    <div v-else>{{text}}</div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import {
        DxLookup,
        DxDropDownOptions
    } from 'devextreme-vue/lookup';
    
    import organizationApi from "../services/OrganizationApi";
import DataSource from 'devextreme/data/data_source';
    import { luceneConverter } from '../services/Util';
import offlineService from '../services/OfflineService';
    let cache = {}

    let OrganizationLookup = defineComponent({
        components: {
            DxLookup,
            DxDropDownOptions
        },
        props: ['value', 'onValueChanged', 'view', 'display', 'showClearButton', 'filteredIds', 'onManualChange','minWidth'],
        data() {
            return {
                dataSource: null,
                editableValue: null,
                text: null,
                tooltipData: {}
            };
        },
        watch:{
            async editableValue(newVal, oldVal) {
                if (newVal != oldVal) {
                    this.$emit('update:value', this.editableValue);
                    if (this.onValueChanged)
                        this.onValueChanged(this.editableValue);
                }
                if (!this.dataSource || !this.editableValue) {
                    this.text = this.displayExpr(null)
                    return;
                }
                let item = await this.dataSource.loadSingle(this.editableValue)
                this.text = this.displayExpr(item);
                cache[this.editableValue] = item;
            }
        },
        async created() {


            this.dataSource = new DataSource({
                load: async (loadOptions) => {
                    if (offlineService.checkIsOffline) {
                        let data = await organizationApi.getAll();
                        if (this.filteredIds) {
                             data = data.filter(o => this.filteredIds.includes(o._id));
                        } 
                        for (let org of data) {
                            org.Name = org.name;
                            org.EMail = org.eMail;
                        }
                        return {
                            data: data, totalCount: data.length
                        }
                    }
                    if (loadOptions.filter) {
                        let query = luceneConverter.toLucene(loadOptions.filter);
                        
                        let result = await organizationApi.search(query, loadOptions.take, loadOptions.skip, "Name,EMail,ZipCode,City,StreetAndNr")
                        return {
                            data: result.organizations, totalCount: result.totalOrganizationHits
                        };
                    } else if (loadOptions.searchValue) {
                        let result = await organizationApi.search("**:" + loadOptions.searchValue, loadOptions.take, loadOptions.skip, "Name,EMail,ZipCode,City,StreetAndNr");
                        return {
                            data: result.organizations, totalCount: result.totalOrganizationHits
                        }
                    } else {
                        let result = await organizationApi.search("*:*", loadOptions.take, loadOptions.skip, "Name,EMail,ZipCode,City,StreetAndNr");
                        return {
                            data: result.organizations, totalCount: result.totalOrganizationHits
                        }
                    }
                },
                byKey: async (key) => {
                    console.log("byKey", key);

                  
                    try {
                        if (!key) {                            
                            return null;
                        }
                            
                        if (key in cache)
                            return cache[key]
                        let result = await organizationApi.search("_id:" + key, 1, 0, "Name,EMail,ZipCode,City,StreetAndNr");
                        if (!result) {
                            console.warn("organization by key not found", key);
                        }
                        
                        var retVal = result.totalOrganizationHits ? result.organizations[0] : { _id: key, Name: "{Organization not found}", EMail: "", ZipCode: "", City: "", StreetAndNr: "" };                      

                        return retVal;
                    }
                    catch (ex) {
                        console.error("failed to fetch organization by key:", key, ex);
                    }
                },
                key: "_id"
            });
            if (this.filteredIds && this.filteredIds.length > 0) {
                this.dataSource.filter(["_id", ":", luceneConverter.anyOf(this.filteredIds)])
            }
            this.editableValue = this.value;
        },
        methods:{
            displayExpr(r) {
                if (this.display)
                    return this.display(r);
                if (!r)
                    return null;
                return r.Name;
            },
            getTooltipText(key) {
               if (!key) return '';

               if (this.tooltipData[key]) return this.tooltipData[key];

                organizationApi.get(key).then(result => {
                    this.tooltipData[key] = result.name + "\n" +  result.streetAndNr + "\n" + result.zipCode + " " + result.city;
                });
               
               return this.tooltipData[key];                                               
            },
            manualChange(e) {
                if (this.onManualChange) {
                    if (e.value) {
                        if (e.event) {
                            this.onManualChange(e.value);
                        }
                    }
                    else
                        this.onManualChange(this.editableValue);
                }
            }
        }
    });
    export default OrganizationLookup;
</script>

<style>
</style>
